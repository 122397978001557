var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.list_heis,"fields":_vm.fields,"items-per-page":_vm.small ? 20 : 8,"dark":_vm.dark,"pagination":"","table-filter":"","items-per-page-select":"","sorter":""},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary",on:{"click":function($event){$event.preventDefault();return _vm.openModalPlantingTrees(item)}}},[_c('i',{staticClass:"bi bi-card-text text-primary"})])])]}},{key:"no_students",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.addComma(item.no_students))+" ")])]}},{key:"total_target_trees_to_be_planted",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.addComma(item.app_trees_per_student))+" ")])]}},{key:"remaining_trees_to_be_planted",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.addComma((item.app_trees_per_student) - item.planted_total_trees))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }